import { useCombinedRefs } from '@hooks/useCombinedRefs';
import { useFullstoryElement } from '@hooks/useFullstory';
import { FullStoryType } from '@utils/fullstory';
import { omit } from 'lodash-es';
import { forwardRef, useRef } from 'react';
import {
  SupportedElementWithDebounce,
  useDebouncedOnChange,
} from '../../hooks/useDebouncedOnChange';

// Browser versions have supported a range of options here throughout the years.
// Passing an 'not_recognized' option seems to be working as of sep 2020.
// Looks like as of oct 2020 it stopped working.
// Instead the 'off' value works for date and geo fields in chrome now.
export const AUTOCOMPLETE_OFF_VALUE = 'off';

export type Props = SupportedElementWithDebounce<HTMLInputElement> &
  FullStoryType;

export const baseInputAttrs = {
  autoComplete: AUTOCOMPLETE_OFF_VALUE,
  // ⬇ Force no autocomplete for 1Password
  'data-1p-ignore': true,
};

export const Input = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { getFsComponentProps } = useFullstoryElement();
  const debounceRef = useRef<HTMLInputElement>(null);
  const { val, onChange, cancel, setVal } = useDebouncedOnChange(
    { ...props, ref: debounceRef },
    !props.debounce
  );

  const inputRef = useCombinedRefs(ref, debounceRef);

  const rest = omit(props, ['debounceCacheKey']);
  const { fsType, fsElement, fsParent } = props;
  return (
    <input
      css={{
        width: '100%',
      }}
      {...baseInputAttrs}
      {...rest}
      {...getFsComponentProps({
        parent: fsParent ?? (props?.id?.toString() as string),
        element: fsElement,
        type: fsType,
      })}
      onChange={onChange}
      onBlur={(e): void => {
        cancel();
        setVal(e.target.value);
        props.onBlur?.(e);
      }}
      value={val}
      ref={inputRef}
    />
  );
});
