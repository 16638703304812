import { ADOBE_FONT } from '@components/Theme/constants';
import { useTheme } from '@hooks/useTheme';
import { createCalendar } from '@internationalized/date';
import { FC, useRef } from 'react';
import { useDateField, useDateSegment, useLocale } from 'react-aria';
import { useDateFieldState } from 'react-stately';
import { useMount } from 'react-use';
import { enUSLocaleFormat } from '../../DatepickerV2';
import { DateType } from '../defs';

interface DateSegmentProps {
  segment: anyOk;
  state: anyOk;
  showYears?: boolean;
}

const DateSegment: FC<DateSegmentProps> = ({
  segment,
  state,
  showYears = false,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useMount(() => {
    state.setSegment('year', new Date().getFullYear().toString());
  });
  const { segmentProps } = useDateSegment(segment, state, ref);
  const {
    colors: { primary, textReverse, disabledText },
  } = useTheme();
  const isSlash = segment.type === 'literal';

  // as of writing, leading zeroes were not found to be supported natively by react-aria
  // add them here, only for non-literal segments
  const segmentText =
    segment.type !== 'literal' ? segment.text.padStart(2, '0') : segment.text;
  return (
    <div
      {...segmentProps}
      ref={ref}
      css={{
        padding: 2,
        fontVariantNumeric: 'tabular-nums',
        textAlign: 'end',
        fontSize: 14,
        minWidth: isSlash ? undefined : '2.6ch',
        color: segment.isPlaceholder || isSlash ? disabledText : undefined,
        '&:focus': {
          background: primary,
          color: textReverse,
          borderRadius: 3,
        },
      }}
      hidden={segment.type === 'year' && showYears === false}
    >
      {segmentText}
    </div>
  );
};

interface Props {
  onChange: (val: DateType) => void;
  value?: Maybe<DateType> | null;
  singleDate?: boolean;
  showYears?: boolean;
  nonClearable?: boolean;
}

export const DateField: FC<Props> = (props) => {
  const { locale } = useLocale();
  const isLocaleEnUSFormat = enUSLocaleFormat.has(locale);
  const state = useDateFieldState({
    ...(props as fixMe),
    granularity: 'day',
    locale,
    createCalendar: createCalendar as fixMe,
  });
  const ref = useRef<HTMLDivElement>(null);

  const { fieldProps } = useDateField(
    {
      ...props,
      'aria-label': 'Date',
      granularity: 'day',
      hideTimeZone: true,
    },
    state as fixMe,
    ref as anyOk
  );

  return (
    <div
      ref={ref}
      css={{
        // modify the show/hide the '/' based on MM/DD/YYYY or DD/MM/YYYY
        '[aria-label="year, Date"] + div': {
          display: props?.showYears
            ? 'block'
            : isLocaleEnUSFormat
            ? 'none'
            : 'block',
        },
        '[aria-label="année, Date"] + div': {
          display: props?.showYears
            ? 'block'
            : isLocaleEnUSFormat
            ? 'none'
            : 'block',
        },
        // modify the show/hide the '/' based on MM/DD/YYYY or DD/MM/YYYY
        '[aria-label="month, Date"] + div': {
          display: isLocaleEnUSFormat ? 'block' : 'none',
        },
        // modify the show/hide the '/' based on MM/DD/YYYY or DD/MM/YYYY
        '[aria-label="mes, Date"] + div': {
          display: isLocaleEnUSFormat ? 'block' : 'none',
        },
        '[aria-label="day, Date"] + div': {
          display: isLocaleEnUSFormat ? 'none' : 'block',
        },
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          fontFamily: ADOBE_FONT,
        }}
      >
        <div
          {...fieldProps}
          css={
            props?.singleDate
              ? {
                  display: 'inline-flex',
                  padding: '2px 4px',
                  borderRadius: '2px',
                  width: '100%',
                }
              : {
                  display: 'inline-flex',
                  padding: '2px 4px',
                  borderRadius: '2px',
                }
          }
        >
          {props?.singleDate ? (
            <span css={{ display: 'flex' }}>
              {state.segments.map((segment, i) => (
                <DateSegment
                  key={i}
                  segment={segment}
                  state={state}
                  showYears={props?.showYears || false}
                />
              ))}
            </span>
          ) : (
            state.segments.map((segment, i) => {
              return (
                <DateSegment
                  key={i}
                  segment={segment}
                  state={state}
                  showYears={props?.showYears || false}
                />
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};
