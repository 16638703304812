import { Button } from '@components/Button';
import { Icon } from '@components/Icon';
import { canUseSpeechToText, useSpeechToText } from '@hooks/useSpeechToText';
import { useTheme } from '@hooks/useTheme';
import { FC } from 'react';
import { useUnmount } from 'react-use';

interface SpeechButtonProps {
  onTranscriptUpdate: (transcript: string) => void;
  onListenStart?: () => void;
  className?: string;
}

export const SpeechButton: FC<SpeechButtonProps> = ({
  onTranscriptUpdate,
  className,
}) => {
  const { isListening, startListening, stopListening } = useSpeechToText({
    onTranscriptUpdate,
  });
  const { gray } = useTheme();

  const canUse = canUseSpeechToText();

  useUnmount(() => {
    stopListening();
  });

  return (
    <Button
      className={className}
      title={
        !canUse
          ? 'Your browser does not support speech recognition. Use Chrome or compatible WebKit browser.'
          : isListening
          ? 'Listening...'
          : 'Start Listening'
      }
      disabled={!canUse}
      css={{ minWidth: 40 }}
      onClick={(e) => {
        e.preventDefault();
        if (isListening) {
          stopListening();
        } else {
          startListening();
        }
      }}
    >
      {isListening && <Icon i="ellipsis" color="primary" size="md" />}
      {!isListening && (
        <Icon i="microphone" color={canUse ? 'primary' : gray[80]} size="md" />
      )}
    </Button>
  );
};
