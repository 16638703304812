import { Icon } from '@components/Icon';
import { VisuallyHidden } from '@components/VisuallyHidden';
import { useTheme } from '@hooks/useTheme';
import { win } from '@utils/win';
import { atom, useAtom } from 'jotai';
import { parse } from 'query-string';
import { FC } from 'react';

interface ChatAtomState {
  open?: boolean;
}

const initialOpen = Boolean(parse(win.location.search).chat);

export const chatAtom = atom<ChatAtomState>({ open: initialOpen });

export const ChatButton: FC = () => {
  const { card } = useTheme();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [chatState, setChat] = useAtom(chatAtom);

  return (
    <button
      id={'chat-button'}
      title="Open Chat"
      css={{
        padding: '0 5px',
      }}
      onClick={(): void => {
        setChat({
          open: !chatState.open,
        });
      }}
    >
      <Icon
        i="commentDots"
        color={card.background}
        size="lg"
        css={{
          pointerEvents: 'none',
          // flip the icon
          transform: 'scaleX(-1)',
        }}
      />
      <VisuallyHidden>Open Chat</VisuallyHidden>
    </button>
  );
};
