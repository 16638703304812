import { useWhoAmI } from '@app/WhoAmI';
import { chatAtom } from '@app/layout/ChatButton';
import { Grid } from '@components/Grid';
import { Icon } from '@components/Icon';
import { CSSObject } from '@emotion/react';
import styled from '@emotion/styled';
import { EmployeeDetailsV2Fragment } from '@generated/fragments/employeeDetailsEmployeeTreeV2';
import { EmployeeSimpleV2Fragment } from '@generated/fragments/employeeSimpleV2';
import { useTheme } from '@hooks/useTheme';
import { HEADER_HEIGHT, SIDEBAR_SEARCH_HEIGHT } from '@utils/constants';
import { useAtom } from 'jotai';
import { isNil } from 'lodash-es';
import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMountedState } from 'react-use';
import { EmployeeEntityTabs } from './EmployeeEntityTabs';
import { Search } from './Search';
import { SearchType } from './Search/types';
import { BASE_HEADER_HEIGHT } from './constants';
import {
  CLOSED_SIDEBAR_WIDTH,
  EmployeeTreeNodeValue,
  SIDEBAR_BORDER_WIDTH,
  SIDEBAR_OPEN_WIDTH,
  SIDEBAR_TRANSITION_MS,
  SidebarContext,
  SidebarContextType,
  TOGGLE_AREA_HEIGHT,
  showElectronPlaceholder,
} from './util';

const isActive: CSSObject = {
  width: SIDEBAR_OPEN_WIDTH,
};

type EmployeeType = Maybe<EmployeeDetailsV2Fragment>;

interface SidebarControlsProps {
  setEmployee: (b: EmployeeType) => void;
  employee: EmployeeType;
  setSearchType: Dispatch<SetStateAction<SearchType>>;
  searchType: SearchType;
  updateEmployeeTreeNodeValue: (emp: EmployeeTreeNodeValue) => void;
  isOpen: boolean;
}

export function useSidebarContext(): SidebarContextType {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error('useSidebarContext must be used within a SidebarContext');
  }
  return context;
}

const SidebarControls: FC<SidebarControlsProps> = ({
  setEmployee,
  setSearchType,
  searchType,
  employee,
  isOpen,
  updateEmployeeTreeNodeValue,
}) => {
  const { employee: currentEmployee } = useWhoAmI();
  const isMounted = useMountedState();

  const [isReadyToShow, setIsReadyToShow] = useState(false);
  const [isShowing, setIsShowing] = useState(false);

  useEffect(() => {
    const readyTimeout = setTimeout(
      () => {
        if (isMounted()) {
          setIsReadyToShow(isOpen);
        }
      },
      isOpen ? SIDEBAR_TRANSITION_MS + 50 : 0
    );
    const showingTimeout = setTimeout(
      () => {
        if (isMounted()) {
          setIsShowing(isOpen);
        }
      },
      isOpen ? SIDEBAR_TRANSITION_MS + 100 : 0
    );
    return (): void => {
      clearTimeout(readyTimeout);
      clearTimeout(showingTimeout);
    };
  }, [isMounted, isOpen]);

  useEffect(() => {
    if (currentEmployee && isNil(employee)) {
      setEmployee(currentEmployee as EmployeeType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEmployee]);

  const resetRepFilter = (): void => {
    setEmployee(currentEmployee as EmployeeType);
  };
  return (
    <div
      css={{
        opacity: isShowing ? 1 : 0,
        height: '100%',
        display: isReadyToShow ? 'grid' : 'none',
        gridTemplateRows: `${SIDEBAR_SEARCH_HEIGHT}px 1fr`,
        overflow: 'hidden',
        width: isReadyToShow ? SIDEBAR_OPEN_WIDTH : 0,
        transition: 'opacity 200ms',
      }}
    >
      <Search
        setEmployee={setEmployee}
        setSearchType={setSearchType}
        searchType={searchType}
      />
      {employee && (
        <EmployeeEntityTabs
          isSidebarOpen={isOpen}
          employee={employee as EmployeeSimpleV2Fragment}
          searchType={searchType}
          resetRepFilter={resetRepFilter}
          updateEmployeeTreeNodeValue={updateEmployeeTreeNodeValue}
        />
      )}
    </div>
  );
};

const TrafficLights: FC = () => {
  const { colors, gray } = useTheme();
  const TrafficLight = styled.div({
    borderRadius: '100%',
    width: 12,
    height: 12,
    border: `1px solid ${gray[99]}`,
  });

  return (
    <Grid cols={3} gap={0}>
      <TrafficLight
        css={{
          background: colors.error,
        }}
      />
      <TrafficLight
        css={{
          background: colors.warning,
        }}
      />
      <TrafficLight
        css={{
          background: colors.success,
        }}
      />
    </Grid>
  );
};

export const Sidebar: FC = () => {
  const sidebarContextData = useContext(SidebarContext);
  const [chatState] = useAtom(chatAtom);
  const { isOpen, toggleOpen, isSidebarAllowed } = sidebarContextData;
  const { sidebar, gray, card: cardColors } = useTheme();

  useEffect(() => {
    if (chatState.open && isOpen) {
      toggleOpen();
    }
  }, [chatState.open, isOpen, toggleOpen]);

  const sidebarWrapper: CSSObject = useMemo(
    () => ({
      width: CLOSED_SIDEBAR_WIDTH,
      overflow: 'hidden',
      height: `calc(100vh - ${HEADER_HEIGHT}px)`,
      position: 'sticky',
      top: HEADER_HEIGHT,
      transition: `width ${SIDEBAR_TRANSITION_MS}ms`,
      borderRight: `${SIDEBAR_BORDER_WIDTH}px solid ${gray[90]}`,
    }),
    [gray]
  );

  if (!isSidebarAllowed) {
    if (showElectronPlaceholder()) {
      const notifyWidth = 80;
      return (
        <div
          data-testid="electron-controls-placeholder"
          css={{
            top: 0,
            left: 0,
            height: BASE_HEADER_HEIGHT,
            width: notifyWidth,
            background: cardColors.background,
            marginRight: notifyWidth * -1,
            zIndex: 1000,
            padding: '16px 4px 0 16px',
          }}
          title="This is a placeholder for traffic lights (mac) OS controls when electron app is in use. The intention is to provide a warning that no content should be placed in this area."
        >
          <TrafficLights />
        </div>
      );
    }
    return <div />;
  }

  return (
    <div
      css={[sidebarWrapper, isOpen && isActive, sidebar]}
      data-testid="sidebar"
      data-sidebar-open={isOpen.toString()}
    >
      <Grid
        xs="1fr"
        css={{
          placeItems: 'center',
          gridTemplateRows: `${TOGGLE_AREA_HEIGHT}px 1fr`,
          height: '100%',
        }}
        gap={0}
      >
        <button
          title={`${isOpen ? 'Close' : 'Open'} Sidebar`}
          css={{ width: '100%', height: '100%' }}
          onClick={(): void => toggleOpen()}
          data-testid="sidebar-toggle"
        >
          <Icon i="bars" color="primary" size="lg" />
        </button>
        <SidebarControls {...sidebarContextData} />
      </Grid>
    </div>
  );
};
