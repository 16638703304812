import { Button } from '@components/Button';
import { Icon, IconProp } from '@components/Icon';
// eslint-disable-next-line no-restricted-imports
import { newColors } from '@components/Theme/colors';
import styled from '@emotion/styled';
import { FS_UNMASK } from '@utils/fullstory';
import { HTMLProps } from 'react';
import {
  AlertTypeProps,
  BUTTONS_BY_TYPE,
  BUTTONS_BY_TYPE_INVERSE,
  ICONS_BY_TYPE,
  SystemAlertType,
  TITLE_BY_TYPE,
} from './constants';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
`;

const Title = styled.div<AlertTypeProps>((props) => ({
  margin: '0 0 0 8px',
  color: newColors.system[props.type].title,
  textAlign: 'center',
  fontSize: 14,
  fontWeight: '700',
}));

interface HeaderProps {
  type: SystemAlertType;
  title?: string;
  unmaskTitle?: boolean;
}

export const SystemAlertHeader = ({
  type,
  title,
  unmaskTitle,
}: HeaderProps): JSX.Element => {
  return (
    <Header>
      <Icon
        name="info"
        i={ICONS_BY_TYPE[type] as IconProp}
        color={newColors.system[type].accent as fixMe}
        size="md"
      />
      <Title type={type} className={unmaskTitle ? FS_UNMASK : undefined}>
        {title ? title : TITLE_BY_TYPE[type]}
      </Title>
    </Header>
  );
};

interface DismissButtonProps extends HTMLProps<HTMLButtonElement> {
  type: SystemAlertType;
  onClick: () => void;
  style?: React.CSSProperties;
  dismiss?: {
    label?: string;
    onClick?: () => void;
  };
}

export const AlertDismissButton = ({
  type,
  onClick,
  style,
  dismiss,
}: DismissButtonProps): JSX.Element => {
  const handleClick = (): void => {
    dismiss?.onClick && dismiss.onClick();
    onClick();
  };
  return (
    <Button
      className={FS_UNMASK}
      option={BUTTONS_BY_TYPE_INVERSE[type]}
      onClick={handleClick}
      style={{ fontWeight: 500, ...buttonWidthStyle, ...style }}
      aria-label="Dismiss Notification"
      v1
    >
      {dismiss?.label ? dismiss.label : 'Dismiss'}
    </Button>
  );
};

const buttonWidthStyle = {
  maxWidth: 114,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const StyledButton = styled(Button)({
  ...buttonWidthStyle,
  marginRight: 12,
  fontWeight: 500,
  ':hover': {
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.3)',
  },
});

interface AlertActionButtonProps {
  type: SystemAlertType;
  action: {
    label: string;
    onClick: () => void;
  };
  style?: React.CSSProperties;
  closeToast?: () => void;
}

export const AlertActionButton: React.FC<AlertActionButtonProps> = ({
  type,
  action,
  style,
  closeToast,
}) => {
  const handleClick = (): void => {
    if (closeToast) {
      action.onClick();
      closeToast();
    } else {
      action.onClick();
    }
  };

  return (
    <StyledButton
      className={FS_UNMASK}
      option={BUTTONS_BY_TYPE[type]}
      onClick={handleClick}
      style={style}
      data-testid="alert-action-btn"
      v1
    >
      {action.label}
    </StyledButton>
  );
};
