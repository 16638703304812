import {
  Provider as SpectrumProvider,
  defaultTheme,
} from '@adobe/react-spectrum';
import { Grid } from '@components/Grid';
import { Padding } from '@components/Padding';
import { Route } from '@components/Route';
import { GlobalStatusDialog } from '@components/SystemAlerts/StatusDialog/GlobalStatusDialog';
import { useFlagMe172708FeatChat } from '@generated/flags/ME-172708-feat-chat';
import { useFlagappShowOfflineMessage } from '@generated/flags/app-show-offline-message';
// eslint-disable-next-line no-restricted-imports
import { PRIVATE_colors } from '@components/Theme/colors';
import { useFlagMe215873UatEnvironmentIndicator } from '@generated/flags/ME-215873-uat-environment-indicator';
import { UserPreferences } from '@hooks/UserPreferences';
import { initWindowManager } from '@hooks/useElectronWindowManagement';
import { useTheme } from '@hooks/useTheme';
import {
  HEADER_HEIGHT,
  IS_LOCAL_DEV,
  IS_PROD_DOMAIN,
  USE_THEME_DARK,
} from '@utils/constants';
import { useFullstory } from '@utils/fullstory';
import { history } from '@utils/history';
import { win } from '@utils/win';
import { PermissionsProvider } from '@views/Common/Permissions';
import { FC, ReactNode, StrictMode, useEffect } from 'react';
import { Router, Switch } from 'react-router-dom';
// eslint-disable-next-line no-restricted-imports
import { BasisTheoryApiKeyProvider } from '@components/EncryptedInput/Context';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNetwork } from 'react-use';
import { QueryParamProvider } from 'use-query-params';
import { SaveProvider } from '../components/SaveContext';
import { Theme } from '../components/Theme';
import { AppRoutes } from './AppRoutes';
import { Chat } from './Chat';
import { ChatProvider } from './Chat/Provider';
import { TestEnvironmentIndicator } from './EnvironmentWrapper';
import { GlobalVariables } from './GlobalVariables';
import { Masterfind, MasterfindProvider } from './Masterfind';
import { MasterFindGlobalStateProvider } from './Masterfind/MasterFindGlobalStateProvider';
import { NavShortcuts } from './NavShortcuts';
import { Sidebar } from './Sidebar';
import { SidebarProvider } from './Sidebar/util';
import { AppStyles } from './Styles';
import { TenantConfig } from './TenantConfig';
import { AuthWrapper } from './auth/AuthWrapper';
import { MasteryKeycloakProvider } from './auth/keycloak';
import { Header } from './layout/Header';

initWindowManager();

function determineDomain(): boolean {
  return IS_LOCAL_DEV || !IS_PROD_DOMAIN;
}

const Content: FC = ({ children }) => {
  const {
    shell: { content },
  } = useTheme();
  const { online } = useNetwork({
    online: win.navigator.onLine,
  });
  const showOfflineMessage = useFlagappShowOfflineMessage();
  if (!online && showOfflineMessage) {
    return (
      <Padding a2>
        <h2>No active network connection.</h2>
      </Padding>
    );
  }
  return (
    <div id="content" css={{ height: '100%', ...content }}>
      {children}
    </div>
  );
};
const domainCheck = determineDomain();
const useDisplayEnvironmentIndicator = (): void => {
  const displayEnvIndicatorFlag = useFlagMe215873UatEnvironmentIndicator();
  const displayEnvironmentIndicator = domainCheck && displayEnvIndicatorFlag;
  useEffect(() => {
    if (displayEnvironmentIndicator) {
      document.documentElement.style.setProperty(
        '--header-stripe-color',
        `${PRIVATE_colors.primaryDark}`
      );
    }
  }, [displayEnvironmentIndicator]);
};

export const App: FC = () => {
  useFullstory();
  const shouldUseChat = useFlagMe172708FeatChat();
  useDisplayEnvironmentIndicator();
  return (
    <StrictMode>
      <GlobalVariables />
      <SaveProvider>
        <UserPreferences>
          <SpectrumProvider
            id="spectrum-provider"
            theme={defaultTheme}
            colorScheme={USE_THEME_DARK ? 'dark' : 'light'}
          >
            <Theme>
              <AppStyles />
              <ToastContainer
                enableMultiContainer
                newestOnTop
                limit={1}
                closeButton={false}
                closeOnClick={false}
                style={{ width: 'max-content', maxWidth: 1000 }}
                transition={Slide}
                containerId={'systemBanner'}
                position="top-center"
              />
              <ToastContainer
                enableMultiContainer
                limit={4}
                closeButton={false}
                closeOnClick={false}
                style={{ width: 250 }}
                transition={Slide}
                containerId={'toast'}
              />
              <NavShortcuts />
              <MasteryKeycloakProvider>
                <TestEnvironmentIndicator>
                  <Router history={history}>
                    <QueryParamProvider ReactRouterRoute={Route}>
                      <Route
                        render={(): ReactNode => {
                          return (
                            <Switch>
                              <AuthWrapper>
                                <PermissionsProvider>
                                  <MasterFindGlobalStateProvider>
                                    <ChatProvider>
                                      <SidebarProvider>
                                        <TenantConfig>
                                          <BasisTheoryApiKeyProvider>
                                            <Header />
                                            <Grid
                                              xs={
                                                shouldUseChat
                                                  ? 'min-content 1fr min-content'
                                                  : 'min-content 1fr'
                                              }
                                              gap={0}
                                              css={{
                                                minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
                                                gridTemplateRows: 'auto',
                                              }}
                                            >
                                              <Sidebar />
                                              <div
                                                css={{
                                                  height: '100%',
                                                }}
                                              >
                                                <MasterfindProvider>
                                                  <Content>
                                                    <AppRoutes />
                                                    <Masterfind />
                                                    <GlobalStatusDialog />
                                                  </Content>
                                                </MasterfindProvider>
                                              </div>
                                              {shouldUseChat && <Chat />}
                                            </Grid>
                                          </BasisTheoryApiKeyProvider>
                                        </TenantConfig>
                                      </SidebarProvider>
                                    </ChatProvider>
                                  </MasterFindGlobalStateProvider>
                                </PermissionsProvider>
                              </AuthWrapper>
                            </Switch>
                          );
                        }}
                      />
                    </QueryParamProvider>
                  </Router>
                </TestEnvironmentIndicator>
              </MasteryKeycloakProvider>
            </Theme>
          </SpectrumProvider>
        </UserPreferences>
      </SaveProvider>
    </StrictMode>
  );
};
