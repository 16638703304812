import {AllFlags} from './defs';
export const slim: Partial<Record<AllFlags, boolean | number | string | Record<string, never>>> = {
 "accounting-page-limit": 0,
 "admin-release-lock": false,
 "admin-vendor-required-docs": false,
 "app-allow-debug": false,
 "app-keycloak-min-validity-seconds": 0,
 "app-keycloak-trigger-refresh-seconds": 0,
 "app-reload-on-auth-timeout": false,
 "app-show-offline-message": false,
 "app-verbose-error-display": false,
 "customer-CRM": false,
 "electronic-tracking": false,
 "home-pending-received-page-limit": 0,
 "load-documents-generate-bol": false,
 "nav-admin": false,
 "offers-board-routes-batch-size": 0,
 "offers-page-auto-load": false,
 "rmis-request-update": false,
 "route-carrier-documents": false,
 "route-carrier-load-history": false,
 "route-lock-expiration-warning-modal-seconds": 0,
 "temp-schedule-stop-appointments": false,
 "test-bool-flag": false,
 "track-page-auto-load": false,
 "track-page-auto-polling-ms": 0,
 "vendor-associated-required-documents": false,
 "ME-6601-feat-unpost-all-routes-on-available-routes": false,
 "ME-8615-view-route-best-offer-card": false,
 "ME-8914-feat-route-posting-status-card": false,
 "ME-8932-feat-booking-events-subscription": false,
 "ME-9205-feat-commissions-global-configuration": false,
 "ME-9210-feat-commissions-customer-overhead-configuration": false,
 "ME-9300-feat-commissions-employee-configuration": false,
 "ME-10011-view-repeat-order-status-reason-code": false,
 "ME-10144-fix-part-1-remove-load-data-refresh": false,
 "ME-10294-view-request-to-book-on-route": false,
 "ME-11055-feat-trailer-id-validation": false,
 "ME-11200-fix-disable-dropdown-refocus": false,
 "ME-11727-feat-route-vendor-reps": false,
 "ME-11793-app-portfolio-pagination-number": 0,
 "ME-12090-view-offers-modal-best-offer-card": false,
 "ME-12224-view-allocations-tab": false,
 "ME-12577-view-autocomplete-address": false,
 "ME-12617-view-submit-offer-action-button": false,
 "ME-12653-feat-RMIS-enhancements-ph1": false,
 "ME-13254-view-recreate-option-in-invoices-table-kebab": false,
 "ME-13869-feat-driver-linked-routes": false,
 "ME-14060-view-load-commissions-tab": false,
 "ME-14209-view-exception-reason": false,
 "ME-14627-view-add-offer-with-response": {},
 "ME-14627-view-add-offer-with-response-board-main": {},
 "ME-14627-view-offers-modal-advertised-price-card": {},
 "ME-14627-view-offers-modal-posting-status-card": {},
 "ME-14627-view-offers-modal-pricing-details-card": {},
 "ME-14657-view-book-with-bin-from-procure-screen": false,
 "ME-14713-view-carrier-portfolio-option": false,
 "ME-14753-fix-table-card-height": false,
 "ME-14873-feat-distance-details": false,
 "ME-14911-feat-late-reason-code-edi-mapping": {},
 "ME-14914-feat-charge-type-configuration": false,
 "ME-14922-view-route-details-pricing-card": {},
 "ME-15110-view-recreate-and-resend-batch-processing-on-invoice-queue": false,
 "ME-15297-feat-multi-currency": false,
 "ME-15441-feat-handle-historical-pagination": false,
 "ME-15447-view-historical-tab-accounting-info": false,
 "ME-15461-view-sni-suggested-procure": {},
 "ME-16053-enable-client-advertised-card": false,
 "ME-16089-view-advertised-price-card-truck-entry": {},
 "ME-16089-view-best-offer-card-truck-entry": {},
 "ME-16089-view-posting-status-card-truck-entry": {},
 "ME-16089-view-pricing-card-truck-entry": {},
 "ME-16118-view-customer-crm-notes": false,
 "ME-16119-view-carrier-crm-notes": false,
 "ME-16458-view-customer-fuel-flag": {},
 "ME-17060-view-truck-entry-view-route-section": false,
 "ME-17103-view-vrfs-column-under-route-list": false,
 "ME-17123-view-admin-assign-commission-plan": false,
 "ME-17405-view-carrier-compliance-tab": false,
 "ME-17877-feat-table-column-resize-exclude": {},
 "ME-18098-feat-admin-accounting-tax-tab": false,
 "ME-18121-view-historical-total-runs": false,
 "ME-18200-feat-table-context-menu-config": {},
 "ME-19163-feat-hot-route": false,
 "ME-19388-feat-auto-carrier-create-code": false,
 "ME-20198-feat-offers-modal-cca": false,
 "ME-20201-feat-del-date-cca": false,
 "ME-20397-view-ability-to-manually-add-required-document": false,
 "ME-20496-fix-geoautocomplete-city-name-display": false,
 "ME-21675-view-werner-pricing-card-rate-quote": {},
 "ME-21898-view-admin-configuration-approved-users": false,
 "ME-22672-feat-disable-finalized-fuel-status": false,
 "ME-22735-fix-facility-picker-query-limit-number": 0,
 "ME-22840-feat-available-routes-refresh": false,
 "ME-22841-feat-available-routes-lock-refresh": false,
 "ME-23197-view-rate-quote-grid-lanes-page": false,
 "ME-23255-feat-mass-upload-load": false,
 "ME-24173-feat-posting-enhancements": false,
 "ME-24258-feat-mastermind-admin-fuel-services": {},
 "ME-24369-feat-bin-checkbox": false,
 "ME-24383-view-truck-entry-view-route-offers-tab": false,
 "ME-24384-view-truck-entry-view-route-booking-tab": false,
 "ME-24478-view-truck-entry-view-route-drivers-tab": false,
 "ME-24479-view-truck-entry-view-route-commissions-tab": false,
 "ME-24766-view-route-references-table": false,
 "ME-25329-feat-admin-accounting-tax-view-enable-vat": false,
 "ME-25738-view-carrier-crm-documents": false,
 "ME-25865-view-customer-crm-document-tab": false,
 "ME-26484-auto-schedule-checkbox": {},
 "ME-27382-view-enable-mainCarrierRep-column-in-routeBoard": false,
 "ME-28335-view-customer-load-defaults-tab": false,
 "ME-29756-view-remit-to-address-fields": false,
 "ME-30049-feat-available-credit-value": false,
 "ME-30053-view-size-mode-division-search-fields": false,
 "ME-30101-view-mode-size-division-for-invoice": false,
 "ME-30112-view-add-quote-link-on-routing-guide": false,
 "ME-30154-view-vendor-invoice-tab": false,
 "ME-30157-view-route-details-search-fields-vendor-invoice-queue": false,
 "ME-30888-view-dashboard-tab-main-page": false,
 "ME-31132-view-main-page-route-board-pagination-size-number": 0,
 "ME-31432-app-main-page-route-board-polling-ms": 0,
 "ME-31566-feat-patterns-apply": false,
 "ME-31766-view-load-patterns-tab-in-customer-lanes": false,
 "ME-31766-view-load-patterns-tab-in-planning-lanes": false,
 "ME-31848-feat-remove-stop-event-validation-for-edi-testing": false,
 "ME-31865-feat-patterns-multi-route": false,
 "ME-31865-feat-patterns-multi-stop": false,
 "ME-31924-view-load-search-pagination-size-number": 0,
 "ME-32174-feat-assets": false,
 "ME-32458-feat-post-details-modal-checkboxes": false,
 "ME-32857-app-masterfind-regex": "",
 "ME-34050-view-adress-and-exceptions-on-vendor-invoice-grid": false,
 "ME-34085-view-exception-reason-on-vendor-invoice-update-modal": false,
 "ME-34087-view-vendor-invoice-exception-reason-on-vendor-invoice-q": false,
 "ME-34100-fix-combine-date-time": false,
 "ME-34235-app-quiet-network-errors": false,
 "ME-34451-view-rating-source-customer-record-accounting": false,
 "ME-34764-feat-tracking-updates-notify-users": false,
 "ME-34786-view-procure-tab": false,
 "ME-35600-feat-masterfind-debounce-ms": 0,
 "ME-35876-feat-booked-by-hyperlink": false,
 "ME-37245-view-allow-multiple-types-on-facility-address": false,
 "ME-38173-view-group-option-in-sidebar": false,
 "ME-38348-view-carrier-ltl-page": false,
 "ME-38932-view-customer-ROE-rules-engine": {},
 "ME-39185-view-carrier-ROE-rules-engine": {},
 "ME-40577-view-facility-load-defaults-tab": false,
 "ME-40908-view-imdl-tab-under-profile": false,
 "ME-42086-feat-patterns-eligible-days": false,
 "ME-42154-feat-patterns-route-carrier": false,
 "ME-42158-feat-patterns-route-vendor": false,
 "ME-42234-feat-allow-negative-and-zero-line-items": false,
 "ME-42407-feat-patterns-route-max-cost": false,
 "ME-42430-feat-patterns-stop-use-order-stop-facility": false,
 "ME-42435-feat-patterns-days-from-previous-stop": false,
 "ME-42936-view-only-PDF-document-for-manual-association": false,
 "ME-43216-enable-carrier-commitment-manager-fe": false,
 "ME-43600-view-prevent-double-horizontal-scrollbars-in-tables": false,
 "ME-43927-feat-auto-invoicing-processing": false,
 "ME-45127-view-order-requirement-modal": false,
 "ME-45202-feat-disable-fuel-validation-for-invoicing": false,
 "ME-45360-view-revenue-on-procurement-screens": false,
 "ME-45522-view-completed-tab-route-board": false,
 "ME-45916-feat-connect-to-new-mileage-service": false,
 "ME-46010-feat-patterns-minimum-pickup-lead-time": false,
 "ME-46114-feat-commissions-allocations-methods": false,
 "ME-46472-feat-dataexchange": false,
 "ME-46488-feat-tracking-method-column-tracking-updates": false,
 "ME-47252-feat-mass-upload-export-button": false,
 "ME-47881-view-accounting-exceptions": false,
 "ME-48033-feat-multi-route": {},
 "ME-48382-feat-mass-upload-import-button": false,
 "ME-48868-view-four-category-for-settlement-queue": false,
 "ME-48993-view-ctc-allowed-by-customer-column": false,
 "ME-49437-dropdown-component-default-to-react-portal": false,
 "ME-50176-fix-wrap-offers-event": false,
 "ME-50847-app-use-driver-electronic-tracking-type-metadata": false,
 "ME-52592-feat-cost-line-items-tooltip": false,
 "ME-52614-view-ta-stop-pending-checkbox": false,
 "ME-52626-load-execution-rails-decommission": {},
 "ME-52963-feat-order-as-non-taxable": false,
 "ME-53020-view-minion-carrier-rep-type": false,
 "ME-53253-view-enhance-customer-reps": false,
 "ME-53254-view-enhance-carrier-reps": false,
 "ME-53788-feat-refresh-ddt-minionrefreshevent": false,
 "ME-54280-app-table-auto-virtualize-above-row-number": 0,
 "ME-55586-view-carrier-parent-details": false,
 "ME-56282-feat-customer-crm": false,
 "ME-56363-feat-ROR-decommision": false,
 "ME-56463-enable-empty-movement-routes": false,
 "ME-56472-view-customer-bid-tab": false,
 "ME-56819-app-fullstory-enable": false,
 "ME-57389-view-facility-records-documents-tab": false,
 "ME-57583-view-facility-scheduling-size-dropdown-adjustments": false,
 "ME-57656-feat-patterns-effective-expiration-dates": false,
 "ME-57856-feat-vouchering-disable-financials-toggle": false,
 "ME-58030-view-customer-ltl-page": false,
 "ME-58227-view-employee-business-unit-and-project": false,
 "ME-59310-view-facility-record-directions-card": false,
 "ME-59681-view-customer-record-regions-tab": false,
 "ME-60304-view-vendor-route-financial-status-reason-codes-vendor-settlement": false,
 "ME-60594-view-covered-tab-show-railbill-dt-tm-column": false,
 "ME-60805-view-vendor-invoice-tab-on-crarrier-accounting": false,
 "ME-61423-view-carrier-general-website-field": false,
 "ME-61696-view-enable-carrier-groups-table": false,
 "ME-61696-view-enable-electronic-trackings-table": false,
 "ME-61696-view-enable-multiselect-employee-groups": false,
 "ME-61741-view-slack-employee-tree": false,
 "ME-61741-view-slack-share": false,
 "ME-62401-feat-use-electronic-trackings": false,
 "ME-62439-view-export-order-and-route-list": false,
 "ME-62633-view-generic-pricing-quote": {},
 "ME-62633-view-generic-pricing-route": {},
 "ME-62897-view-Facility-Records-Locations-Tab": false,
 "ME-63935-view-vendor-invoice-missing-checkbox": false,
 "ME-64246-feat-load-city-state-stop": false,
 "ME-64689-view-customer-project-businessunit-division-work": false,
 "ME-65672-view-accounting-status": false,
 "ME-65696-view-tender-contact-email-require": false,
 "ME-65797-view-project-page": false,
 "ME-67291-view-facility-project-businessunit-division-work": false,
 "ME-68029-view-add-commodities-spot-quote": false,
 "ME-68031-view-carrier-profile-record": false,
 "ME-68032-view-customer-profile-record": false,
 "ME-68339-feat-Booking-validation-tooltip-component-for-Procure-Tab": false,
 "ME-69336-feat-route-additional-division-business-unit": false,
 "ME-69370-feat-holidays-card": false,
 "ME-69768-view-carrier-record-release-method": false,
 "ME-70314-feat-commodity-modal-enhancements": false,
 "ME-73254-view-dashboard-tab-main-page-for-customer-rep": false,
 "ME-77169-view-statement-num-column": false,
 "ME-77502-view-route-requirement-modal": false,
 "ME-78147-view-split-receiving-facility-schedules": false,
 "ME-79207-view-project-business-unit-for-accounting": false,
 "ME-79243-feat-rate-quotes-import-button": false,
 "ME-81024-feat-deactivate-lane": false,
 "ME-81986-view-cost-line-items-on-booking": false,
 "ME-82913-view-new-simplified-booking-page": false,
 "ME-84078-view-simplified-bookings-route-drawer": false,
 "ME-84102-view-enable-multiselect-employee-division-and-businessunit": false,
 "ME-85141-view-commitments-columns-on-route-board-pending-tab": false,
 "ME-85144-view-commitments-columns-on-customer-operations-pending-tab": false,
 "ME-85177-view-facility-schedule-temperature": false,
 "ME-85430-view-hide-front-page-offers": false,
 "ME-86013-feat-simplified-bookings-kebabs-on-procure-tab": false,
 "ME-87605-feat-booking-from-my-matches": false,
 "ME-87895-view-facility-search-screen-enhancements": false,
 "ME-87966-view-customer-search-screen-enhancements": false,
 "ME-88001-view-employee-search-screen-enhancements": false,
 "ME-88478-view-imdl-phase-2": false,
 "ME-88589-feat-rate-quotes-export-button": false,
 "ME-88734-feat-master-link-connected-tabs": false,
 "ME-88898-feat-simplified-bookings-kebabs-on-capacity-manager": false,
 "ME-88901-feat-simplified-bookings-kebabs-on-offers-tab": false,
 "ME-89139-view-carrier-customer-accessorial-pages": false,
 "ME-89159-view-allowable-free-time-UI": false,
 "ME-89219-feat-shared-stop-events": false,
 "ME-90034-view-carrier-relationships-table-enhancements": false,
 "ME-90035-view-customer-relationships-table-enhancements": false,
 "ME-91630-feat-adjust-credit-limit-validation": false,
 "ME-91812-view-RMISPh2-insurancev2": false,
 "ME-91969-view-orders-associated-to-lane": false,
 "ME-91969-view-routes-associated-to-lane": false,
 "ME-92845-view-facility-schedule-dropdown-multiselect": false,
 "ME-95118-view-customer-record-audit-tab-v2": false,
 "ME-95176-view-employee-record-audit-tab-v2": false,
 "ME-95210-view-facility-record-audit-tab-v2": false,
 "ME-95267-feat-create-pattern-from-order": false,
 "ME-98082-view-facility-relationships-table": false,
 "ME-99230-view-global-vendor-toggle": false,
 "ME-99402-view-saved-filters-pagination-size-number": 0,
 "ME-99915-view-carrier-accounting-tables-in-tabs": false,
 "ME-100806-view-customer-service-tolerances": false,
 "ME-100907-view-driver-fleets": false,
 "ME-100908-view-trailer-fleets": false,
 "ME-101291-view-Customer-Reference-Type": false,
 "ME-102823-view-RMISPh2-certificationv2": false,
 "ME-103094-view-load-execution-tracking-enhancements": false,
 "ME-103899-view-ltl-size-option-spot-quote": false,
 "ME-106651-view-global-customer-toggle": false,
 "ME-107308-feat-Upload-IMDL-Service-Profiles": false,
 "ME-107667-check-executing-division-before-running-trailer-id-validation-in-stop-events-grid": false,
 "ME-108671-view-passthrough-fields-and-approval-fields-ctcV2": false,
 "ME-109305-load-filter-customer-reps-by-division": false,
 "ME-109305-load-sync-for-customer-reps": false,
 "ME-109761-view-RMISPh2-Intrastatev2": false,
 "ME-110202-view-variable-distance-toggle-in-rq-and-cq": false,
 "ME-110633-view-dashboard-tab-main-page-for-carrier-manager": false,
 "ME-113277-view-load-route-section-send-lock": false,
 "ME-114768-view-Waybill-Status-column-on-covered-tab": false,
 "ME-115141-feat-enable-fleet-resources": false,
 "ME-115209-view-driver-reps": false,
 "ME-115498-view-load-execution-tracking-enhancements-p2": false,
 "ME-115898-view-driver-record": false,
 "ME-115902-view-driver-preferences": false,
 "ME-115918-view-driver-record-targets-and-performance": false,
 "ME-115975-view-driver-record-history": false,
 "ME-115985-view-trailer-record": false,
 "ME-115990-view-power-record": false,
 "ME-116123-view-carrier-insurance-insurer-am-best-rating-multiselect": false,
 "ME-116124-view-customer-insurer-am-best-rating-field": false,
 "ME-117305-view-driver-pay": false,
 "ME-117324-feat-customer-specific-late-reason-codes": false,
 "ME-117923-view-ratequote-and-costquote-service-field": false,
 "ME-119723-feat-tilded-region-filters-on-routeboard": false,
 "ME-119777-feat-booking-from-main-page-capacity": false,
 "ME-119951-feat-stop-events-conditional-trailer": false,
 "ME-121127-view-customer-configurations-tab": false,
 "ME-121162-feat-enable-masterfind-expand-search-view": false,
 "ME-121608-feat-allow-five-decimals": false,
 "ME-121648-view-customer-commitment-analytics-tab": false,
 "ME-121999-view-route-pagesize-number": 0,
 "ME-122957-enable-eta-updates-on-tracking-screens": false,
 "ME-123260-view-group-filter-on-mprb": false,
 "ME-123288-migrate-datepicker-to-version-2": false,
 "ME-123294-enable-single-date-column-filters-with-relative-menu": false,
 "ME-123684-show-coordinates-on-address-modal": false,
 "ME-123718-customer-facility-relation-provided-coordinates": false,
 "ME-123935-feat-load-customer-specific-appointment-change-reason": false,
 "ME-125122-view-display-executing-division-and-business-unit-fields": false,
 "ME-125274-feat-BIN-Override": false,
 "ME-125293-view-cost-detail-approvals": false,
 "ME-125293-view-rate-detail-approvals": false,
 "ME-125455-view-assigned-rep-filter-to-MPRB": false,
 "ME-125518-feat-my-drivers-fleet-manager-in-portfolio": false,
 "ME-125564-bill-to-code-dedicated-billing-enhancements-mm-befe": false,
 "ME-125594-feat-booking-from-capacity-manager-matches": false,
 "ME-125842-migration-to-date-range-picker": false,
 "ME-126412-view-driver-schedule-ux-enhancements": false,
 "ME-126476-view-tooltip-on-flat-file-modal": false,
 "ME-127503-feat-Paperwork-Setting-Change-Modal": false,
 "ME-128866-display-abacus-prices-in-sbw": false,
 "ME-128870-feat-table-column-datepicker-filters": false,
 "ME-129313-view-division-column-on-MPRB": false,
 "ME-129317-view-division-filter-to-MPRB": false,
 "ME-129319-view-capacity-need-filter-on-mprb": false,
 "ME-130405-view-stop-service-tolerance": false,
 "ME-131151-feat-use-covered-ratecon": false,
 "ME-132308-view-exceptions-saved-filters-chip": false,
 "ME-132691-view-saved-search-filter-track-screen": false,
 "ME-132830-distance-to-next-backend-usage": false,
 "ME-133369-view-customer-commitment-on-edit-rate-quote-modal": false,
 "ME-134481-feat-load-gate-cut-off-schedule-on-stop": false,
 "ME-134705-view-RMISPh2-Request-Update-Button": false,
 "ME-134866-view-dashboard-tab-main-page-for-customer-mgr": false,
 "ME-135032-view-new-enum-values-carrier-reptype": false,
 "ME-135237-view-driver-power-carrier-route-list": false,
 "ME-135244-feat-tilded-region-filters-on-tracking": false,
 "ME-135827-view-rate-details-percent-of": false,
 "ME-135831-view-cost-details-percentof": false,
 "ME-135936-feat-pattern-cascade-stop-info": false,
 "ME-136258-feat-enable-new-carrier-fuel-tab": false,
 "ME-137062-view-alchemy-region-tool-tip-info": false,
 "ME-137897-view-imdl-mode-option-spot-quote": false,
 "ME-137919-feat-type-counts-in-exceptions-type-dropdown": false,
 "ME-139059-fix-minimum-width-phoneinput-component": false,
 "ME-139407-feat-enable-main-page-capacity-assign-driver-kebab": false,
 "ME-139838-view-early-or-late-cutoff-details": false,
 "ME-139872-feat-validate-stop-types-settings": false,
 "ME-141116-view-driver-pay-settings-card": false,
 "ME-141199-driver-hours-of-service": false,
 "ME-141898-view-carrier-commitment-on-edit-cost-quote-modal": false,
 "ME-142552-feat-display-resources-tab-loaded-outbound-trailers": false,
 "ME-142552-feat-display-resources-tab-trailers-linked-routes": false,
 "ME-143580-view-power-fleets": false,
 "ME-143641-view-group-column-on-mprb": false,
 "ME-143752-view-assigned-rep-column-on-mprb-open-tab": false,
 "ME-143773-view-driver-column-on-mprb": false,
 "ME-144301-view-carrier-identifiers-id": false,
 "ME-144301-view-customer-identifiers-id": false,
 "ME-144461-enable-bookings-bracing-multi-select-ddt": false,
 "ME-144845-view-facility-reps": false,
 "ME-146135-feat-split-route-workflow": false,
 "ME-146573-feat-load-search-seer-v2": false,
 "ME-147234-view-employee-search-division": false,
 "ME-147311-allow-dispatched-driver-assignment-update-if-driver-not-required": false,
 "ME-147970-view-rate-details-tiered-config": false,
 "ME-147983-view-cost-details-tiered-configuration": false,
 "ME-148117-view-new-division-project-dropdowns-records": false,
 "ME-149566-feat-seer-migration-v2": false,
 "ME-150002-view-driver-pay-attributes": false,
 "ME-150693-view-facility-maximum-trailer-age": false,
 "ME-151272-view-route-reference-on-cost-quote": false,
 "ME-151805-enable-change-customer-before-order-save": false,
 "ME-153464-view-external-pricing-toggle-rate-quote-modal": false,
 "ME-153465-view-external-pricing-toggle-cost-quote-modal": false,
 "ME-154178-feat-expanded-load-view-on-mainpage-loadboard": false,
 "ME-154178-feat-expanded-route-view-on-mainpage-routeboard": false,
 "ME-154178-view-flag-column-on-mainpage-routeboard": false,
 "ME-154178-view-loadboard-on-mainpage": false,
 "ME-154188-view-columns-tracking-enhacement-phase-3": false,
 "ME-154188-view-filters-tracking-enhacement-phase-3": false,
 "ME-155038-feat-view-suggested-carriers-v2": false,
 "ME-155449-enable-references-on-all-tracking-screens": false,
 "ME-155555-view-employee-structure-tab": false,
 "ME-156346-view-address-county": false,
 "ME-156393-feat-enable-geographies-statefilter": false,
 "ME-156503-view-accounting-queues-saved-search-filter": false,
 "ME-158427-enable-customer-fuel-configuration-updated-modal": false,
 "ME-158663-view-crm-contact-in-records": false,
 "ME-161064-view-route-pricing-v2-integration": false,
 "ME-161096-enable-bulk-rerating": false,
 "ME-161180-view-alchemy-quote-order-requirements": false,
 "ME-162240-alchemy-quote-fuel-configuration": false,
 "ME-162510-view-location-generated-geocoordinates": false,
 "ME-162526-enable-rate-quote-mass-upload-order-requirements-columns": false,
 "ME-162684-backdating-rq-and-cq": false,
 "ME-162771-view-driver-target-times-enhancement": false,
 "ME-165057-view-carrier-customer-relationship-enhancement": false,
 "ME-165208-resources-tab-trailer-assignment": false,
 "ME-165234-resources-tab-display-container-chassis-for-dray-routes": false,
 "ME-165234-resources-tab-display-container-for-rail-routes": false,
 "ME-165709-view-customer-bid-manager-tab": false,
 "ME-166225-view-customer-type-on-customer-record": false,
 "ME-166320-enable-outbound-214-status-configuration-view": false,
 "ME-166649-view-customer-group-search-filter": false,
 "ME-166881-view-recheck-region-kebab-option": false,
 "ME-166924-view-enable-offers-for-asset": false,
 "ME-167070-feat-load-split-route-milestone-2": false,
 "ME-167541-allow-rm-contact-information-to-be-nullable": false,
 "ME-167942-view-order-disabled-financials": false,
 "ME-167946-enable-disable-financials-option-from-action-type-drop-down": false,
 "ME-168379-view-warning-pop-up-on-route-disable-financials": false,
 "ME-168512-feat-seer-customer-carrier-track-migration-v2": false,
 "ME-168512-feat-seer-facility-track-migration-v2": false,
 "ME-168512-feat-seer-mprb-track-migration-v2": false,
 "ME-168512-feat-seer-route-order-list-migration-v2": false,
 "ME-169031-view-employee-code-null-edit": false,
 "ME-169174-view-password-on-credentials-table": false,
 "ME-169348-enable-out-of-tolerance-modal": false,
 "ME-169582-view-warning-message-carrier-record-tender-contacts-modal": false,
 "ME-169875-view-hos-summary-tab": false,
 "ME-169909-feat-enable-new-data-exchange-view-carrier": false,
 "ME-169912-view-hos-cycle-recap-tab": false,
 "ME-169914-view-hos-history-tab": false,
 "ME-169915-view-hos-audit-history-tab": false,
 "ME-170462-view-carrier-operation-route-list-enhancement": false,
 "ME-170479-view-customer-operations-order-list-enhancement": false,
 "ME-171710-view-portfolio-calendar": false,
 "ME-172333-view-facility-operation-route-list-enhancement": false,
 "ME-172708-feat-chat": false,
 "ME-172813-Employee-Organizations-RM": false,
 "ME-172814-view-additional-division-column-on-mprb": false,
 "ME-173300-enable-customer-carrier-relationship-internal-customer-functionality": false,
 "ME-178279-view-portfolio-phase-3": false,
 "ME-178637-view-region-configuration-on-charge-code": false,
 "ME-180270-view-maxCost-offers-from-federation": false,
 "ME-180652-enable-filter-by-org-on-mptt": false,
 "ME-181263-view-carrier-record-resources-bottom-tab": false,
 "ME-181348-view-structure-tab-on-customer-record": false,
 "ME-183763-view-equipment-management-tab": false,
 "ME-183951-view-driver-audit": false,
 "ME-185958-enable-details-column-on-exceptions-tab": false,
 "ME-186391-Customer-Organizations-RM": false,
 "ME-187321-view-trailer-to-customer-relationship": false,
 "ME-187848-disable-add-voucher-button-when-financials-are-disabled": false,
 "ME-187857-historical-tab-load-query-sorting": false,
 "ME-189090-enable-saved-filters-migration": false,
 "ME-190508-feat-enable-stop-event-drawer-on-load-track-screen": false,
 "ME-190899-autbook-asset-offers-enabled": false,
 "ME-191194-view-rate-con-failure-exception": false,
 "ME-195787-enable-fix-for-lock-does-not-fall-off-when-closing-booking-page": false,
 "ME-195879-enable-mass-booking-page": false,
 "ME-199428-tendering-prevent-missed-appointments-due-to-rollover": false,
 "ME-199429-tendering-business-hours-ui-update": false,
 "ME-200897-resources-tab-unsaved-data-pop-up": false,
 "ME-201047-driver-partner-trainer": false,
 "ME-201049-driver-permanent-trailers": false,
 "ME-201050-driver-permanent-power": false,
 "ME-201291-trailer-permanent-drivers": false,
 "ME-201292-trailer-permanent-power": false,
 "ME-201562-enable-bill-to-customer-and-tender-from-columns-on-mpob": false,
 "ME-201629-power-permanent-drivers": false,
 "ME-201631-power-permanent-trailers": false,
 "ME-201661-create-patterns-for-region-lanes": false,
 "ME-204577-uninvoiced-queue-use-seer": false,
 "ME-204579-unvouchered-queue-use-seer": false,
 "ME-204621-fs-test-event": false,
 "ME-204820-enable-patterns-extended-inherit-facility": false,
 "ME-204840-view-driver-cards": false,
 "ME-205260-enable-saved-filters-for-book-offers-page": false,
 "ME-205554-hide-bulk-insert-button-of-tiered-configuration-from-customer-and-carrier-ctc": false,
 "ME-205643-enable-rmis-request-update-connection-station-feature": false,
 "ME-206864-enable-saved-filters-for-main-page-offers-tab": false,
 "ME-207019-notes-and-documents-upgrade-node-service": false,
 "ME-207078-enable-support-of-lane-type-for-rqmu": false,
 "ME-209679-custom-distance-columns-for-rqmu": false,
 "ME-211796-view-additional-fields-and-filters-on-mpob": false,
 "ME-213009-pass-driver-info-to-advance": false,
 "ME-213237-enable-validation-type-for-canvas-warning-and-required-options": false,
 "ME-213552-spot-quote-pricing-card": false,
 "ME-213647-view-rate-detail-queue-mm-fe": false,
 "ME-214355-spectrum-upgrade-dialog": false,
 "ME-214484-view-dashboard-tab-main-page-for-crm-rep": false,
 "ME-215018-resources-tab-driver-pay-modal": false,
 "ME-215424-import-rate-line-items-fe": false,
 "ME-215873-uat-environment-indicator": false,
 "ME-216160-enable-invoice-hold": false,
 "ME-216398-view-level-filter-enhancements": false,
 "ME-216505-enhance-user-interface-for-scheduling-tab": false,
 "ME-216977-display-edi-tab-with-auto-refresh": false,
 "ME-217186-enable-assign-trailer-capacity-to-routes": false,
 "ME-217654-enable-auto-accept-lead-time-configurations-on-customer-commitment-manager": false,
 "ME-217802-view-facility-structures-tab": false,
 "ME-217907-feat-portfolio-driver-scalability-enhancements": false,
 "ME-218790-display-scheduling-tab": false,
 "ME-219273-enable-pattern-additional-divisions": false,
 "ME-219827-view-power-audit": false,
 "ME-219990-sales-interactions-migration": false,
 "ME-220062-procurement-division-filtering-new-reqs": false,
 "ME-220243-enable-planned-network-management": false,
 "ME-220462-feat-display-reason-code-in-minion-order": false,
 "ME-221158-awards-updates-work": false,
 "ME-221160-enable-default-pu-date-filter-on-load-search-page": false,
 "ME-222784-enable-customer-configuration-canvas-tab": false,
 "ME-222798-enable-regions-columns-for-rqmu": false,
 "ME-223286-enable-hiding-of-exceptions-on-canceled-loads-or-routes": false,
 "ME-223563-trailer-record-reason-codes": false,
 "ME-223764-enable-async-bol-document-generation": false,
 "ME-223961-inherit-pay-program-checkbox": false,
 "ME-224477-enable-insert-stop-at-sequence": false,
 "ME-224499-enable-orderType-in-mastermind": false,
 "ME-225091-geofencing-customer-toggle-flag": false,
 "ME-225091-geofencing-facility-toggle-flag": false,
 "ME-225112-carrier-route-list-division-and-type-columns": false,
 "ME-225117-facility-route-list-division-and-type-columns": false,
 "ME-225121-customer-order-list-division-and-type-columns": false,
 "ME-225258-multiplel-load-stop-type-support": false,
 "ME-225390-display-mass-booking-column-on-mprb": false,
 "ME-226678-update-button-color-palette": false,
 "ME-226688-readable-timezones": false,
 "ME-226740-carrier-organization": false,
 "ME-227268-view-directions-and-scalenotes-on-stop-details": false,
 "ME-227600-connect-main-page-tracking-to-mydriver": false,
 "ME-227809-view-carrier-record-authorized-divisions": false,
 "ME-228134-view-carrier-header-enhancements": false,
 "ME-228196-view-customer-header-enhancements": false,
 "ME-228197-trailer-record-field-adjustments": false,
 "ME-228198-view-facility-header-enhancements": false,
 "ME-228213-view-employee-header-enhancements": false,
 "ME-229124-view-additional-stopevents-with-live-drop-logic": false,
 "ME-229619-view-trailer-audit": false,
 "ME-230659-next-available-preference-reason": false,
 "ME-233435-alchemy-rqupload-ext-pricing": false,
 "ME-233900-enable-trailer-capacity-editor-grid-to-trailer-record": false,
 "ME-234630-enable-voucher-hold": false,
 "ME-235284-enable-customer-cascade-workflow": false,
 "ME-235325-feat-my-drivers-display-remaining-hos": false,
 "ME-235744-enable-utilization-status-phase-2-frontend": false,
 "ME-236045-enable-next-callback-date-chip-filter": false,
 "ME-238047-view-rate-cost-line-item-tasks": false,
 "ME-238251-enable-carrier-configuration-tab": false,
 "ME-239125-enable-scheduled-pick-up-date-commitment": false,
 "ME-239335-connect-main-page-tracking-to-mprb": false,
 "ME-239646-view-power-current-next-routes": false,
 "ME-239966-enable-destination-facility-and-reload-intent-in-carrier-general-defaults": false,
 "ME-240050-view-incidents-tab-on-main-page": false,
 "ME-240512-enable-viewing-customer-configurations-rating-tab": false,
 "ME-240520-enable-viewing-carrier-configurations-rating-tab": false,
 "ME-241325-driver-routing-requests": false,
 "ME-241997-stamp-executing-division-voucher": false,
 "ME-242090-feat-new-certification-fields": false,
 "ME-243584-enable-load-sync-for-all-divisions-customer-reps": false,
 "ME-243803-carrier-route-list-org": false,
 "ME-243807-facility-route-list-org": false,
 "ME-244596-enable-driver-calculated-next-available-date-time": false,
 "ME-244874-view-driver-messaging": false,
 "ME-245101-feat-origin-and-destination-facility-columns-and-filters": false,
 "ME-245345-view-commodity-modal-enhancement-bottom-fields-and-checkbox": false,
 "ME-246713-use-contact-defaults-from-carrier-record": false,
 "ME-247364-resources-tab-use-standard-trailer-picker": false,
 "ME-247381-resources-tab-use-route-division-metadata-for-trailer-resource-type": false,
 "ME-247535-resources-tab-allow-accessories-for-trailer-resource-type-change": false,
 "ME-247538-resources-tab-use-route-type-for-default-trailers-in-loaded-and-outbound": false,
 "ME-247865-enable-expected-date-times-for-stop-events": false,
 "ME-248326-masterfind-search-for-asset-objects": false,
 "ME-250088-feat-carrier-tab-enhancement-on-mainpage-sidebar": false,
 "ME-250099-feat-customer-tab-enhancement-on-mainpage-sidebar": false,
 "ME-250106-view-facility-tab-on-mainpage-sidebar": false,
 "ME-250367-view-exception-notes-followup-date": false,
 "ME-250544-carrier-record-new-ddt-voucher-method": false,
 "ME-251675-enable-order-credit-bypass": false,
 "ME-251916-enable-weekly-monthly-surcharge-calculation-enhancements": false,
 "ME-252001-records-date-range-picker": false,
 "ME-252618-display-stop-commodities-dividing-commodity-quantities": false,
 "ME-252642-use-main-apollo-client-for-lazy-promise-util": false,
 "ME-252971-carrier-search-screen-enhancements": false,
 "ME-253129-use-new-executing-division-logic": false,
 "ME-255970-use-flag-to-hide-or-display-all-work-around-emr-linking": false,
 "ME-256006-enable-carrier-lanes-tab": false,
 "ME-256006-enable-planning-lanes-tab": false,
 "ME-256085-enable-power-location-ping-for-tracking-updates-grid": false,
 "ME-256443-enable-scac-editing-on-rate-quote": false,
 "ME-256541-resources-tab-disable-autodispatching-for-fleet-resources": false,
 "ME-256598-resources-tab-hide-ncbt-and-eta-fields-for-fleet-driver-type": false,
 "ME-256604-use-order-credit-info-query": false,
 "ME-256850-resources-tab-enable-dispatched-ready-fields": false,
 "ME-256856-resources-tab-standard-driver-picker-component": false,
 "ME-256860-resources-tab-default-driver-assignment-type-based-on-division-and-minion-flag": false,
 "ME-257433-driver-current-next-routes": false,
 "ME-257503-hide-carrier-accounting-tab": false,
 "ME-258122-enable-rate-quote-mass-upload-fuel-designation-column": false,
 "ME-258547-rename-route-reference-column-to-reference-on-tracking-screens": false,
 "ME-258782-enable-preplans-buttons-from-load-resources-tab": false,
 "ME-258976-enable-cost-quote-fuel-designation-column": false,
 "ME-258976-enable-fuel-in-cqmu-fe": false,
 "ME-259432-power-record-last-ping": false,
 "ME-260009-view-exception-search-screen-enhancements": false,
 "ME-260186-driver-preferences-updates": false,
 "ME-260276-set-reload-intent-based-on-the-carriers-reload-intent-default-for-sbw": false,
 "ME-260671-fuel-range-file-import": false,
 "ME-261744-enable-mass-upload-costquotes-export-button": false,
 "ME-261744-enable-mass-upload-costquotes-import-button": false,
 "ME-263672-enable-free-search-for-geography-components": false,
 "ME-263902-use-tender-type-in-default-carrier-contact-logic-for-tendering": false,
 "ME-264012-attach-document-to-opportunities": false,
 "ME-264101-enable-data-exchange-ob-990-field-mapping-frontend": false,
 "ME-264808-enable-columns-tracking-enhancement-phase-4": false,
 "ME-265156-enable-intelligent-capacity-destination": false,
 "ME-265933-http-known-endpoint-security": false,
 "ME-266123-driver-tender-initiation-from-main-page": false,
 "ME-266125-enable-driver-tenders-tab-on-main-page-route-board": false,
 "ME-266294-enable-outbound-edi-for-manual-loads": false,
 "ME-266653-enable-customer-configurations-priority-quote-type": false,
 "ME-266757-enable-carrier-cascade-workflow": false,
 "ME-267874-enable-trailer-assignments-via-stop-events": false,
 "ME-268492-view-default-order-profile": false,
 "ME-269032-enable-mile-maker-v-2-provider": false,
 "ME-269272-enable-pricing-cards-in-sbw-book-a-carrier": false,
 "ME-269599-enable-table-customization-settings-migration": false,
 "ME-269690-carrier-structure-tab": false,
 "ME-269768-rate-cons-repeat-send-max-delay": 0,
 "ME-270078-sales-opportunities-migration": false,
 "ME-270146-enable-edi-management-tab": false,
 "ME-270197-resources-tab-preserve-manually-entered-power-unit": false,
 "ME-271195-view-hold-reason-column-on-mpob-and-mprb": false,
 "ME-272958-atlas-table-component-prevent-scroll-callbacks-for-column-filtering": false,
 "ME-273113-crm-filters-on-customer-search-screen-enhancement": false,
 "ME-274940-feat-move-pudaterange-in-saved-filters": false,
 "ME-275104-mm-frontend-ai-load-search": false,
 "ME-275776-driver-messaging-main-page": false,
 "ME-276185-datepicker-default-min-max-values": false,
 "ME-276723-enable-masterfind-load-bottom-tabs-drawer-collapsed": false,
 "ME-277855-basis-theory-secure-pii-component": false,
 "ME-277866-send-to-open-button-calls-both-mutations": false,
 "ME-278575-power-record-ping-history": false,
 "ME-278732-portfolio-calendar-default-time-filters": false,
 "ME-278936-view-current-power": false,
 "ME-279389-resources-tab-disable-required-trailer-type-validation": false,
 "ME-280089-manage-groups": false,
 "ME-282122-load-display-commodity-tags": false,
 "ME-283150-table-component-list-view-functionality": false,
 "ME-283977-view-current-driver": false,
 "ME-284245-view-intermediate-column-on-mprb": false,
 "ME-284247-view-reference-column-on-mprb-open-tab": false,
 "ME-284286-flat-file-service-platform-view": false,
 "ME-284297-prevent-space-in-facility-code": false,
 "ME-285316-hours-of-service-auto-refresh": false,
 "ME-286293-enable-route-types-in-cost-quote": false,
 "ME-286723-view-available-routes-list-view": false,
 "ME-287076-tender-eligibility": false,
 "ME-287227-ai-email-parsing": false,
 "ME-287941-view-invoicing-requirements-modal": false,
 "ME-287944-view-vouchering-requirements-modal": false,
 "ME-288418-resources-tab-use-route-primary-division-metadata-for-trailer-resource-type": false,
 "ME-288632-enable-common-component-for-tracking": false,
 "ME-288949-enable-optimatch-v-3": false,
 "ME-290238-enable-api-elements-phase-2": false,
 "ME-290250-enable-stopevents-split-ux": false,
 "ME-290713-view-bypass-compliance-in-customer-carrier-relationship-table": false,
 "ME-290926-enable-enhance-reload-intent-capacity-to-leverage-etd": false,
 "ME-291685-certifications-and-permits-id": false,
 "ME-291697-enable-driver-availability-calendar": false,
 "ME-292490-resources-tab-open-fleet-type-routes-workflow": false,
 "ME-292612-enable-tracking-updates-default-limit": false,
 "ME-293524-enable-lazy-load-paginiation-in-tracking-updates": false,
 "ME-294313-enable-sbw-enhancements-part-3": false,
 "ME-294980-view-pre-plan-sent-column-on-mprb-covered": false,
 "ME-294981-view-pre-plan-sent-column-on-portfolio-drivers": false,
 "ME-295044-driver-routing-requests-timezone": false,
 "ME-295088-display-executing-division-field-on-carrier-fuel": false,
 "ME-295549-automatic-create-return-trip-ib-204-frontend": false,
 "ME-295569-resources-tab-hide-3-pi-tracking-fields-on-fleet-type-routes": false,
 "ME-295572-resources-tab-make-reload-intent-read-only-on-completed-routes": false,
 "ME-295572-resources-tab-read-only-for-emr-routes": false,
 "ME-296507-enable-customer-cascade-multiple": false,
 "ME-296580-enable-carrier-cascade-multiple": false,
 "ME-296907-feat-enter-hold-reason": false,
 "ME-297508-enable-sbw-enhancements-part-1": false,
 "ME-297508-enable-sbw-enhancements-part-2": false,
 "ME-297533-enable-xest-outputs": false,
 "ME-297539-enable-control-for-current-state-vs-transitional-state-of-electronic-tracking": false,
 "ME-297861-load-payable-mileage-calculation": false,
 "ME-298493-context-menu-ux-modifications": false,
 "ME-298495-manage-render-order-of-context-menuitems": false,
 "ME-299313-enable-capacity-driver-resolvers": false,
 "ME-299467-employee-search-org-dropdown": false,
 "ME-299534-enable-note-expand-feature": false,
 "ME-299870-enable-canadian-carrier-feature": false,
 "ME-300341-operational-type-employment-type": false,
 "ME-300495-enable-edi-management-tab-phase-2": false,
 "ME-301632-ai-capacity-max-input-length": 0,
 "ME-304547-enable-capacity-verbose-error-message": false,
 "ME-304558-feat-swap-legacy-assign-driver-kebab-with-new-kebab-main-page-capacity": false,
 "ME-305704-driver-record-display-last-ping-location": false,
 "ME-306252-restrict-external-notes-length": false,
 "ME-306330-enable-capacity-need-type-trailer": false,
 "ME-306607-enable-portfolio-driver-next-routing-request-column": false,
 "ME-307528-feat-task-refactor-for-non-load-use-case": false,
 "ME-307628-enable-scac-field-on-order-drawer": false,
 "ME-309814-ai-capacity-max-items-for-creation": 0,
 "ME-309944-power-record-location-pings-workflows": false,
 "ME-310359-enable-quote-type-input-for-carrier-commitment": false,
 "ME-310814-enable-tracking-tab-consistency": false,
 "ME-312224-enable-available-routes-references": false,
 "ME-312298-chip-with-content-floating-fix": false,
 "ME-312305-enable-tender-from-ids-for-order-profile": false,
 "ME-312345-view-driver-column-on-mytask-tab": false,
 "ME-312431-enable-cutoff-times-and-driver-target-datetime": false,
 "ME-314139-driver-record-personal-information-sub-tab": false,
 "ME-315804-customer-order-list-reference-and-time-columns": false,
 "ME-315932-enable-route-type-in-cqmu-fe": false,
 "ME-315939-enable-routing-guide-streaming-export": false,
 "ME-316451-show-phos-details-tooltip-in-stopevents-grid": false,
 "ME-317509-use-datepicker-v-2-on-stop-events-grid": false,
 "ME-318225-enable-carrier-fuel-cost-quotes-only-checkbox": false,
 "ME-318669-view-dist-column-on-mprb": false,
 "ME-318670-view-intermediate-stops-column-on-mprb-tabs": false,
 "ME-318671-view-stops-column-on-mprb-tabs": false,
 "ME-319073-view-pu-time-del-time-column": false,
 "ME-319074-feat-customer-name-mprb-mpob": false,
 "ME-319076-feat-hyperlink-carrier-name-on-mprb": false,
 "ME-319077-view-column-and-chip-filter-route-type-on-mprb": false,
 "ME-319078-view-driver-code-column-on-mprb": false,
 "ME-319324-enable-data-exchange-ob-214-field-mapping-frontend": false,
 "ME-321160-enable-groups-global-component": false,
 "ME-321712-enable-tracking-updates-filter-query": false,
 "ME-322051-driver-record-ping-history": false,
 "ME-322517-view-last-ping-columns-on-driver-portfolio": false,
 "ME-323686-displays-or-hides-financial-trip-fields-and-data-in-the-unvouchered-queue": false,
 "ME-324277-route-assignment-validation": false,
 "ME-324709-enable-reference-column-mprb": false,
 "ME-324710-enable-reference-column-mpob": false,
 "ME-325055-candian-carrier-phase-2-enhancements": false,
 "ME-325875-view-load-defaults-order-profile-inactive-checkbox": false,
 "ME-326512-date-picker-dismiss-on-shortcut-key": false,
 "ME-327967-customer-commodity-defaults-enhancements": false,
 "ME-328427-rate-quote-platform-upload": false,
 "ME-328445-ltl-flatfile-platform-upgrade": false,
 "ME-328461-routing-guide-platform-upload": false,
 "ME-328480-service-profile-platform-upload": false,
 "ME-330195-enable-trailer-capacity-need-matching": false,
 "ME-330518-driver-payrate-platform-upload": false,
 "ME-331001-core-load-platform-upload": false,
 "ME-331159-enable-resolve-retrigger-transactions": false,
 "ME-332126-use-order-division-as-nullable-in-order-profile": false,
 "ME-332983-enable-location-updates-sending-restrictions": false,
 "ME-333075-enable-rate-quote-streaming-export": false,
 "ME-333453-carrier-resources-tab-enhancements": false,
 "ME-333983-add-ppll-feature-code": false,
 "ME-334109-enable-nullable-order-division-enhancement": false,
 "ME-334901-main-page-portfolio-table-customization-fix": false,
 "ME-335491-table-id-dynamic-prop-fix-apply-wide": false,
 "ME-339366-restrict-internal-notes-length": false,
 "ME-341231-fix-multi-word-field-validation-format": false
};
