import { REQUIRED_FIELD_ERROR_STRING } from '@components/Field';
import {
  USER_MUST_SAVE_ERROR_KEY,
  USER_MUST_SAVE_ERROR_MESSAGE,
} from '@components/TableInForm/util';
import { ButtonTooltip, TooltipProps } from '@components/Tooltip';
import { useFlagMe341231FixMultiWordFieldValidationFormat } from '@generated/flags/ME-341231-fix-multi-word-field-validation-format';
import { flattie } from 'flattie';
import { useFormikContext } from 'formik';
import {
  capitalize,
  compact,
  identity,
  isString,
  startCase,
  uniqueId,
} from 'lodash-es';
import { FC, useEffect, useRef, useState } from 'react';
import { Button, ButtonProps } from '..';
import { useSaveContext } from '../../SaveContext';
import { GENERIC_REQUIRED_FIELD_MSG, TOOLTIP_ERROR_LIST_CLASS } from './util';

export interface Props extends Omit<ButtonProps, 'disabled' | 'type'> {
  group?: string;
  disabled?: (baseDisabled: boolean) => boolean | string;
  loading?: boolean;
  id: string;
  onClick?: () => Promise<void> | void;
  positionType?: TooltipProps['positionType'];
  type?: 'button' | 'submit';
}

export const getFormattedFormikError = (
  key: string,
  val: string,
  formatWithTitleCase = false
): string | null => {
  try {
    // If the type that Yup expects is not met, we get these specific errors
    // They are verbose and not very helpful to the user
    if (val.match('but the final value was:')) {
      return null;
    }
    // If the error is nested in an object, it's hard to automatically display something that will make sense, so we fallback to more general messages (see unit tests)
    if (key.match(/\./)) {
      if (val === REQUIRED_FIELD_ERROR_STRING) {
        return GENERIC_REQUIRED_FIELD_MSG;
      } else {
        return val.replace(key, '').trim();
      }
    }
    if (val === REQUIRED_FIELD_ERROR_STRING) {
      const formattedKey = formatWithTitleCase
        ? startCase(key)
        : capitalize(key);
      return `${formattedKey} is required.`;
    } else if (val.startsWith(key)) {
      return capitalize(val);
    }
    return val;
  } catch (err: anyOk) {
    return null;
  }
};

export const getFormattedFormikErrors = (
  errors: Record<string, string>,
  formatWithTitleCase = false
): string | null => {
  const flattened = flattie(errors);
  const entries = Object.entries(flattened);
  if (!entries.length) {
    return null;
  }
  if (entries.find(([key]) => key === USER_MUST_SAVE_ERROR_KEY)) {
    return USER_MUST_SAVE_ERROR_MESSAGE;
  }
  const errorList = compact(
    Object.entries(flattened).map(([rawKey, val]) =>
      getFormattedFormikError(rawKey, val, formatWithTitleCase)
    )
  );
  return `<ul class="${TOOLTIP_ERROR_LIST_CLASS}">${errorList
    .map((str) => `<li>${str}</li>`)
    .join('')}</ul>`;
};

export const SaveButton: FC<Props> = ({
  group: rawGroup,
  disabled = identity,
  loading,
  onClick,
  positionType,
  ...rest
}) => {
  const formatWithTitleCase =
    useFlagMe341231FixMultiWordFieldValidationFormat();

  const hasPushed = useRef<boolean | null>(null);
  const buttonRef = useRef(null);
  const { current: group } = useRef(rawGroup || uniqueId('saveButtonGroup'));
  const { current: saveId } = useRef(uniqueId('saveButtonId'));
  const [isClicking, setIsClicking] = useState(false);

  const { push, remove } = useSaveContext();

  // This component might not always be wrapped in a Formik form, but usually it is.
  // If it is not, then formik does not throw an error, but it does output to console.warn as of 2.2.3
  const {
    isValid = true,
    isSubmitting = false,
    submitForm = undefined,
    errors,
    touched = false,
  } = useFormikContext() || {};

  useEffect(() => {
    if (buttonRef.current && !hasPushed.current) {
      push({ ref: buttonRef.current, group, id: saveId, htmlId: rest.id });
      hasPushed.current = true;
    }
    return (): void => {
      remove(saveId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const baseDisabled = Boolean(
    !isValid || isSubmitting || loading || isClicking || !touched
  );

  const disabledVal = disabled(baseDisabled);

  const formattedErrors = getFormattedFormikErrors(errors, formatWithTitleCase);
  const shouldShowErrorsTooltip =
    (formattedErrors && Boolean(disabledVal)) || isString(disabledVal);

  return (
    <ButtonTooltip
      label={formattedErrors || disabledVal}
      css={{ padding: '0 10px' }}
      hidden={!shouldShowErrorsTooltip}
      positionType={positionType || 'top'}
    >
      <Button
        disabled={Boolean(disabledVal)}
        type="button"
        {...rest}
        onClick={async (): Promise<void> => {
          setIsClicking(true);
          if (onClick) {
            await onClick();
          } else if (submitForm) {
            await submitForm();
          }
          setIsClicking(false);
        }}
        ref={buttonRef}
        data-save-btn
      >
        {rest.children || 'Save'}
      </Button>
    </ButtonTooltip>
  );
};
